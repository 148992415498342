<template>
  <div>
    <v-layout row>
      <v-flex xs12 sm12 md12 lg12 x12>
        <v-card class="pa-3">
          <v-progress-linear
            :active="isLoading"
            :indeterminate="isLoading" 
            :height="1"
            class="pa-0 ma-0"
          />
          <div v-if="!isLoading">
            <v-layout row>
              <v-flex xs4>
                <v-layout row>
                  <v-flex xs3>
                    <v-avatar v-if="userData.image" size="65px">
                      <img :src="userData.image" alt="">
                    </v-avatar>
                    <v-avatar v-else size="65px">
                      <v-icon class="avatar-icon">
                        mdi-account
                      </v-icon>
                    </v-avatar>
                  </v-flex>

                  <v-flex xs9>
                    <div
                      class="headline text--darken-1 mb-2 break"
                      v-text="userData.username"
                    ></div>
                    <div
                      class="subheading text--darken-1 break"
                      v-text="userData.name"
                    ></div>
                  </v-flex>
                </v-layout>

                <div class="mt-35-p mb-3">
                  <div class="subheading bold mb-1 grey--text">
                    Email
                  </div>
                  <div class="break" v-text="userData.email"></div>
                </div>

                <div class="mb-3">
                  <div class="subheading bold mb-1 grey--text">
                    Gender
                  </div>
                  <div v-text="userData.gender ? userData.gender : '-'"></div>
                </div>

                <div class="mb-3">
                  <div class="subheading bold mb-1 grey--text">
                    Bitrhday
                  </div>
                  <div v-text="userData.birthday"></div>
                </div>

                <div class="mb-3">
                  <div class="subheading bold mb-1 grey--text">
                    Homecountry
                  </div>
                  <div
                    v-text="userData.country ? userData.country.name : '-'"
                  ></div>
                </div>
              </v-flex>

              <v-flex xs4>
                <div>
                  <div class="subheading bold mb-1 grey--text">
                    Role
                  </div>

                  <div class="select-wrap">
                    <v-select
                      v-model="userRole"
                      :items="userRoleList"
                      :disabled="disableRole"
                      :menu-props="{ bottom: true, offsetY: true }"
                      hide-selected
                      item-text="name"
                      item-value="id"
                      return-object
                      solo
                      box
                      @change="changeUserRole()"
                    />
                  </div>
                </div>

                <div class="mb-3">
                  <div class="subheading bold mb-1 grey--text">
                    Registration date
                  </div>
                  <div v-text="dateFormat(userData.registration_date)"></div>
                </div>

                <div v-if="userData.banned" class="mb-3">
                  <div class="subheading bold mb-1 grey--text">
                    Banned reason
                  </div>
                  <div v-text="userData.banned.reason_reports.reason"></div>
                </div>
                <div v-if="userData.banned" class="mb-3">
                  <div class="subheading bold mb-1 grey--text">
                    Banned note
                  </div>
                  <div
                    class="text-wrap"
                    v-text="
                      userData.banned.reason_description
                        ? userData.banned.reason_description
                        : '-'
                    "
                  ></div>
                </div>
              </v-flex>

              <v-flex xs4>
                <div class="mb-4">
                  <div class="headline grey--text">
                    Karma:
                    <span
                      class="bold white--text"
                      v-text="userData.karma"
                    ></span>
                  </div>
                </div>

                <div class="mb-4">
                  <div class="title grey--text mb-2">
                    General info
                  </div>

                  <v-list>
                    <div class="mb-1">
                      <v-icon class="blue-grey--text mr-2">
                        mode_comment
                      </v-icon>
                      <b v-text="userData.comments_count"></b> Comments
                    </div>
                    <div class="mb-1">
                      <v-icon class="blue-grey--text mr-2">
                        photo
                      </v-icon>
                      <b v-text="userData.posts_count"></b> Active posts
                    </div>
                    <div class="mb-1">
                      <v-icon class="blue-grey--text mr-2">
                        thumb_up
                      </v-icon>
                      <b
                        class="teal--text"
                        v-text="userData.user_set_like_up_votes_count"
                      ></b>
                      Upvotes
                    </div>
                    <div class="mb-1">
                      <v-icon class="blue-grey--text mr-2">
                        thumb_down
                      </v-icon>
                      <b
                        class="red--text"
                        v-text="userData.user_set_like_down_votes_count"
                      ></b>
                      Downvotes
                    </div>
                  </v-list>
                </div>

                <div v-if="userData.reports" class="mb-5">
                  <router-link
                    :to="`/moderation/user_list/reported_user/${userData.id}`"
                    class="blue-grey--text link-hover"
                  >
                    <span class="headline">
                      Reports:
                      <span class="bold" v-text="userData.reports"></span>
                    </span>
                  </router-link>
                </div>
              </v-flex>

              <v-flex v-if="isModel" xs4>
                <div class="headline grey--text mb-4">
                  Monetization
                </div>
                <div>
                  <div class="subheading bold mb-1 grey--text">
                    Period
                  </div>
                  <div class="select-wrap">
                    <v-select
                      v-model="selectedPeriod"
                      :items="period"
                      :menu-props="{ bottom: true, offsetY: true }"
                      hide-selected
                      item-text="title"
                      item-value="val"
                      return-object
                      solo
                      box
                      @change="selectPeriod"
                    />
                  </div>
                </div>
                <div class="d-flex align-center justify-space-between mb-3">
                  <div class="subheading bold grey--text">
                    User’s profit
                  </div>
                  <div :class="[userData.profit > 0 ? 'green-text' : 'red-text','bold fz-20']">
                    ${{ userData.profit }}
                  </div>
                </div>
                <div>
                  <router-link
                    :to="`/user-transaction-history/${userData.id}`"
                    class="bold fz-16"
                  >
                    Transaction History
                  </router-link>
                </div>
                <div>
                  <v-switch
                    v-model="userData.monetization"
                    class="custom-switch"
                    label="Monetization"
                    :disabled="userData.monetization === null"
                    :ripple="false"
                    hide-details
                    inset
                    @change="changeMonetization"
                  />
                  <div v-if="userData.monetization === null" class="mt-2">
                    User is not verified yet
                  </div>
                </div>
              </v-flex>
            </v-layout>
            <div class="text-xs-right">
              <v-btn flat class="blue-grey ma-0" @click="returnBack()">
                <v-icon left dark class="font-size-20">
                  arrow_back
                </v-icon>
                Back
              </v-btn>
            </div>
          </div>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { logoutRequest } from "../../../../../api/auth"
import { changeUserMonetization, userPeriodRequest } from "../../../../../api/monetization"
import moment from "moment"
import { resetAccessToken } from "../../../../../utils/axios"
import { log } from "../../../../../utils/helpers"
import {
  findUserByIdRequest,
  getUsersRules,
  userChangeVisitedStatus,
  userChangesRole
} from "../../../../../api/user"
import { fetchData } from "../../../../../utils/response"
export default {
  name: "ViewUserPage",
  data() {
    return {
      selectedPeriod: "current_month",
      period: [
        {
          val: "current_month",
          title: "Current month"
        },
        {
          val: "previous_month",
          title: "Previous month"
        },
        {
          val: "last_3_months",
          title: "The last 3 months"
        },
        {
          val: "current_year",
          title: "Current year"
        },
        {
          val: "last_year",
          title: "Last year"
        },
        {
          val: "all_time",
          title: "All time"
        }
      ],
      isLoading: false,
      userData: {},
      userRole: {},
      userRoleList: [],
      prevRoute: null,
      disableRole: true,
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  computed: {
    ...mapGetters(["getUserInfoAndPermissions"]),
    isModel() {
      return this.userData.rule.toLowerCase() === 'model'
    },
  },
  created() {
    this.fetchUserData()
    log(moment().format())
  },
  methods: {
    disableChangeRole() {
      if (this.userData.rule === 'Model') {
        this.userRoleList = ['Model']
        this.userRole =  'Model'
        this.disableRole = true
      } else this.disableRole = this.getUserInfoAndPermissions.role === 'Moderator'
          || this.userData.id === 1
    },
    changeMonetization() {
      changeUserMonetization(this.userData.id, this.userData.monetization)
          .then(() => {})
          .catch(this.$notificationHandleResponseError)
    },
    selectPeriod(){
      const params = {
        user_id: this.userData.id,
        period: this.selectedPeriod.val
      }
      userPeriodRequest(params)
          .then(res => {
            this.userData.profit = res.data
          })
          .catch(this.$notificationHandleResponseError)
      console.log(params)
    },
    changeUserRole() {
      userChangesRole(this.userData.id, this.userRole.id)
        .then(response => {
          if (
            this.getUserInfoAndPermissions.id === this.userData.id &&
            this.userRole.id === 3
          ) {
            this.onLogout()
          } else {
            this.$notificationShow(response.data[0])
          }
        })
        .catch(this.$notificationHandleResponseError)
    },
    returnBack() {
      if (this.prevRoute && this.prevRoute.fullPath === "/") {
        this.$router.push({ name: "users" })
      } else {
        this.$router.go(-1)
      }
    },
    async fetchUserData() {
      this.isLoading = true
      await findUserByIdRequest(this.$route.params.id)
        .then(fetchData)
        .then(user => {
          this.userData = user
          // this.userRoleList = this.userData.rules;
          this.setUserRole(this.userData.rule)
        })
        .catch(this.$notificationHandleResponseError)
      await getUsersRules()
        .then(fetchData)
        .then(roleList => {
          this.userRoleList = roleList
          this.setUserRole(this.userData.rule)
        })
        .catch(this.$notificationHandleResponseError)
      await userChangeVisitedStatus(this.userData.id).catch(
        this.$notificationHandleResponseError
      )
      this.isLoading = false
      this.disableChangeRole()
    },
    onLogout() {
      logoutRequest()
        .then(() => {
          this.$notificationShow("You have been logged out")
          this.removeToken()
          this.$router.push({ name: "login" })
        })
        .catch(this.$notificationHandleResponseError)
    },
    removeToken() {
      this.$ls.set("user", "")
      this.$ls.set("access_token", "")
      resetAccessToken()
    },
    setUserRole(userRoleName) {
      let indexUserRule = this.userRoleList.findIndex(
        rule => rule.name === userRoleName
      )
      this.userRole = this.userRoleList[indexUserRule]
    },
    dateFormat(date) {
      return moment(date).format("DD-MM-YYYY")
    }
  }
}
</script>

<style scoped>
.disabled {
  pointer-events: none;
  color: #bfcbd9;
  cursor: not-allowed;
  background-image: none;
  background-color: #eef1f6;
  border-color: #d1dbe5;
}
.select-wrap {
  max-width: 75%;
}

.mt-35-p {
  margin-top: 35px;
}

.link-hover:hover .headline {
  color: #fff;
}

.link-hover:hover {
  text-decoration: none;
}
.avatar-icon {
  font-size: 64px;
}
.text-wrap {
  white-space: normal;
  word-wrap: break-word;
}
</style>
